.react-calendar__tile {
	padding: 15px;
}
.react-calendar__tile:disabled {
	background-color: red !important;
	color: white !important;
}
.current-reservation {
	background-color: rgba(0, 255, 0, 0.3) !important; /* Highlight current reservations in green */
}

.next-reservation {
	background-color: rgba(255, 165, 0, 0.3) !important; /* Highlight next reservations in orange */
}
